<template>
  <div class="goods_item" @click="handler">
    <div class="goods_pic">
      <img width="100%" v-lazy="image" />
    </div>
    <div class="goods_msg">
      <div class="goods_title" ref="GoodsTitle">{{ goodsTitle }}</div>
      <p class="just-for-test" ref="JustForTest">你好呀</p>
      <div class="goods_price">
        <span class="f00 f12">￥{{ goodsPrice }}</span>
      </div>
      <div v-if="!editing" class="goods-ext">
        <div>
          <div class="goods-coupon" v-if="parseFloat(coupon_amount)>0">
            <span class="f12">{{parseFloat(coupon_amount)}}元券</span>
          </div>
        </div>
        <div>
          <div class="share-amount">
            <span class="f12">预估收益¥{{commission}}</span>
          </div>
        </div>
      </div>
      <!-- <div class="timestamp">
        <span class="f12">{{ timestamp }}</span>
      </div>-->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";

Vue.use(Lazyload);

export default {
  name: "HistoryItem",
  props: {
    bgColor: {
      type: String,
      default: "#ef307f",
    },
    goodsId: {
      type: [Number, String],
      default: 0,
    },
    image: {
      type: String,
      default: "",
    },
    goodsTitle: {
      type: String,
      default: "",
    },
    goodsPrice: {
      type: String,
      default: "",
    },
    timestamp: {
      type: String,
      default: "0",
    },
    commission: [String, Number],
    coupon_amount: [String, Number],
    editing: [Boolean],
  },
  data() {
    return {
      title: "",
    };
  },
  watch: {
    goodsTitle: function (newVal, oldVal) {
      // this._initTitle()
    },
  },
  mounted() {
    // this._initTitle()
  },
  methods: {
    _initTitle() {
      let arr = this.goodsTitle.split("");

      let tmpStr = "";
      let need = false;
      let i;
      for (i in arr) {
        tmpStr += arr[i];
        let tmpheight = this._getTextHeight(tmpStr);
        if (tmpheight > 54) {
          need = true;
          break;
        }
      }

      if (need) {
        this.title = arr.splice(0, i - 2).join("") + "...";
      } else {
        this.title = this.goodsTitle;
      }
    },
    _getTextHeight(iText) {
      let that = this;
      String.prototype.visualHeight = function () {
        let dom = that.$refs.JustForTest;
        dom.innerText = this;
        return dom.offsetHeight;
      };

      return iText.visualHeight();
    },
    handler: function () {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.goods_item {
  display: flex;
  padding: 10px 10px 0 10px;
}

.goods_pic {
  position: relative;
  flex: none;
  width: 90px;
  height: 90px;
  text-align: center;
  overflow: hidden;
  margin-right: 10px;
}

.goods_msg {
  position: relative;
  min-width: 0;
  flex: 1;
}

.goods_title {
  font-size: 14px;
  line-height: 18px;
  height: 54px;
  overflow: hidden;
}

.goods_price {
  line-height: 18px;
}

.just-for-test {
  position: absolute;
  visibility: hidden;
  left: 0;
  top: 0;
  font-size: 14px;
  line-height: 18px;
}

.timestamp {
  line-height: 18px;
}
.goods-ext {
  display: flex;
  justify-content: space-between;
}

.share-amount {
  background-image: linear-gradient(90deg, #ff6284 9%, #ffa554 100%);
  border-radius: 4px;
  padding: 1px 6px;
  color: #fe5251;
}

.share-amount span {
  color: #ffffff;
}

.goods-coupon {
  width: 40px;
  height: 16px;
  line-height: 16px;
  background: #99b0ff;
  border-radius: 4px;
  text-align: center;
}

.goods-coupon span {
  color: #fff;
}
</style>
