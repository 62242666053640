// 添加浏览历史
export const add 			= (params) => window._http('/GoodsHistory-add', params)

// 清空浏览历史
export const clear 		= (params) => window._http('/GoodsHistory-clear', params)

// 删除浏览历史
export const remove 	= (params) => window._http('/GoodsHistory-remove', params)

// 获取浏览历史列表
export const getList 	= (params) => window._http('/GoodsHistory-index', params)