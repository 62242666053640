<template>
  <div>
    <div class="page_header">
      <NavBar bg-color="#f8f8f8" title=" ">
        <div slot="right_content">
          <span class="fc03" @click="_clear()">清空</span>&nbsp;&nbsp;&nbsp;
          <span class="fc03" @click="_edit">{{ editing?'完成':'编辑' }}</span>
        </div>
      </NavBar>
    </div>
    <div class="page_body pt50 pb20">
      <van-checkbox-group v-model="check_result">
        <van-list
          v-model="loading"
          :finished="finished"
          :offset="50"
          :finished-text="finished_text"
          :loading="loading"
          :loading-text="loading_text"
          @load="_loadData"
        >
          <div class="goods_item" v-for="(goods, index) in data_list" :key="index">
            <div class="left_checkbox" v-if="editing">
              <van-checkbox
                :name="goods.goods_id"
                @click.native="_checkboxGoodsChange(goods.goods_id)"
                checked-color="#ff4"
                ref="checkboxes"
              />
            </div>
            <div class="right_content">
              <history-item
                :goodsId="goods.goods_id"
                :image="goods.pict_url"
                :goodsTitle="goods.goods_title"
                :goodsPrice="goods.price"
                :timestamp="goods.coupon_amount"
                :commission="goods.commission"
                :coupon_amount="goods.coupon_amount"
                :editing="editing"
                @click="$router.push({path:'/goodsItem', query: {id: goods.goods_id}})"
              />
            </div>
          </div>
        </van-list>
      </van-checkbox-group>
      <template v-if="editing">
        <!-- 编辑删除 -->
        <van-submit-bar button-text="删除" @submit="_removeHistory">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <van-checkbox v-model="del_checked_all" @click.native="_allChecked">全选</van-checkbox>
        </van-submit-bar>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import StatusBar from "@/components/StatusBar";
import NavBar from "@/components/NavBar";
import Goods from "@/components/Goods";
import ls from "@/libs/localstore";
import HistoryItem from "@/components/HistoryItem";
import {
  getList,
  clear as clearHistory,
  remove as removeHistory
} from "@/api/history";
import { Dialog, SubmitBar, CheckboxGroup, List, Checkbox } from "vant";

Vue.use(Dialog)
  .use(SubmitBar)
  .use(CheckboxGroup)
  .use(List)
  .use(Checkbox);

export default {
  name: "History",
  components: {
    StatusBar,
    NavBar,
    HistoryItem,
    Goods
  },
  data() {
    return {
      toPage: 1,
      rows: 10,
      editing: false,
      check_result: [],
      del_checked_all: false,
      data_list: [],
      loading: false,
      loading_text: "加载中",
      finished: false,
      finished_text: "没有更多记录了"
    };
  },
  methods: {
    _edit() {
      this.editing = !this.editing;
    },
    _loadData() {
      let params = {
         
        toPage: this.toPage,
        rows: this.rows
      };
      getList(params)
        .then(res => {
          if (res.errCode == 0) {
            if (res.data.length > 0) {
              this.data_list = this.data_list.concat(res.data);
              this.toPage += 1;
              console.log(this.data_list)
            } else {
              this.finished = true;
            }
          } else {
            this.finished = true;
          }
          this.loading = false;
        })
        .catch(err => {
          this.finished = true;
          this.loading = false;
        });
    },

    _clear() {
      if (this.data_list.length <= 0) {
        return false;
      }

      let params = {
         
      };
      let that = this;
      Dialog.confirm({
        message: "确定要清空历史纪录吗？"
      }).then(() => {
        clearHistory(params).then(res => {
          if (res.errCode == 0) {
            that.toPage = 1;
            that.finished = false;
            that.data_list = [];
          }
        });
      });
    },

    _checkboxGoodsChange(goods_id) {
      if (this.check_result.length == this.data_list.length) {
        this.del_checked_all = true;
      } else {
        this.del_checked_all = false;
      }
    },

    _allChecked() {
      if (this.del_checked_all) {
        for (let i in this.data_list) {
          this.check_result.push(this.data_list[i].goods_id);
        }
      } else {
        this.check_result = [];
      }
    },

    _removeHistory() {
      if (this.data_list.length <= 0) {
        return false;
      }

      let del_goods_ids = this.check_result;
      if (del_goods_ids.length <= 0) {
        this.$toast("请选择要删除的历史纪录商品");
        return false;
      }

      let params = {
         
        goods_ids: del_goods_ids
      };
      let that = this;
      Dialog.confirm({
        message: "确定要删除这些历史纪录吗？"
      }).then(() => {
        removeHistory(params).then(res => {
          if (res.errCode == 0) {
            that.$toast("删除成功");
            for (let i in that.data_list) {
              for (let j in del_goods_ids) {
                if (that.data_list[i].goods_id == del_goods_ids[j]) {
                  that.data_list.splice(i, 1);
                }
              }
            }
          }
        });
      });
    }
  }
};
</script>

<style scoped>
.finished_text {
  color: #9b9b9b;
  margin: 5px 0;
  font-size: 15px;
}

.goods_item {
  display: flex;
  align-items: center;
}

.left_checkbox {
  position: relative;
  flex: none;
  padding: 0 5px 0 15px;
}

.right_content {
  flex: 1;
}
</style>
