<template>
  <div id="container" @click="handler">
    <div class="goods_pic">
      <img height="100%" v-lazy="image" />
    </div>
    <div class="goods_msg">
      <div class="goods_title fc03">{{ goodsTitle }}</div>
      <div class="sale_msg">
        <div class="price_bar">
          <div class="price">
            最高可减: ¥
            <span class="f16 b">{{ goodsPrice-1 }}</span>
          </div>
          <template v-if="isActived">
            <button class="btn btn-actived">马上抢</button>
          </template>
          <template v-else>
            <button class="btn">未开始</button>
          </template>
        </div>
        <div class="btn_bar">
          <div class="old_price">
            原价
            <s>¥{{ goodsPrice }}</s>
          </div>
          <div class="sale_progress">
            <div class="small_text f12">已售65%</div>
            <van-progress :percentage="50" color="#f44" :show-pivot="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Progress, Lazyload } from "vant";

Vue.use(Lazyload).use(Progress);

export default {
  name: "Goods",
  props: {
    bgColor: {
      type: String,
      default: "#ef307f",
    },
    goodsId: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: "",
    },
    goodsTitle: {
      type: String,
      default: "",
    },
    goodsPrice: {
      type: String,
      default: "",
    },
    volume: {
      type: Number,
      default: 0,
    },
    showTag: {
      type: Boolean,
      default: false,
    },
    isActived: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "",
    };
  },
  computed: {
    realLink: function () {
      return this.disable ? "" : this.link;
    },
  },
  methods: {
    handler: function () {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
#container {
  height: 100px;
  padding: 5px;
  position: relative;
  background: #fff;
  display: flex;
  justify-content: space-between;
}

.goods_pic {
  flex: none;
  width: 90px;
  height: 90px;
  margin-right: 10px;
  text-align: center;
  overflow: hidden;
}

.goods_msg {
  position: relative;
  min-width: 0;
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.goods_title {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.price_bar {
  display: flex;
  justify-content: space-between;
}

.btn_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  color: #f40337;
  font-size: 10px;
}
.old_price {
  color: #9b9b9b;
  font-size: 10px;
  line-height: 12px;
}
.sale {
  color: #9b9b9b;
  font-size: 10px;
}

.btn {
  width: 64px;
  height: 23px;
  font-size: 12px;
  text-align: center;
  line-height: 23px;
  border: none;
  outline: none;
  color: #fff;
  background-image: linear-gradient(-90deg, #888 0%, #bbb 100%);
  border-radius: 100px;
}

.btn-actived {
  background-image: linear-gradient(-90deg, #fa0768 0%, #f47103 100%);
}

.sale_progress {
  display: flex;
  justify-content: space-between;
  color: #9b9b9b;
  align-items: center;
}

.small_text {
  flex: none;
  color: #4a4a4a;
  display: inline-block;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.van-progress {
  flex: 1;
  width: 60px;
}
</style>
